.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #7585FF;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #FDC2A0;
}

.below-the-fold {
  padding: 31px;
}

.below-the-fold input {
  padding: 19px;
  font-size: 19px;
}

.App-header .messages {
  font-size: 19px;
  background-color: lightyellow;
  padding: 13px;
  border: 1px solid #1f1f1f;
  border-radius: 6px;
  color: #1f1f1f;
  min-width: 400px;
}
